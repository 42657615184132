//创作中心
<template>
  <div class="write_center">
    <!-- 页面内容部分 -->
    <div class="content" v-if="!showLoading">
      <!--导航栏-->
      <table class="Navigation">
        <tr>
          <td v-for="(item, index) in navigationData" :key="index">
            <span
              :class="currPos == index ? 'select' : 'no_select'"
              @click.stop="onSelect(item, index)"
            >
              {{ item.name }}
            </span>
          </td>
        </tr>
      </table>
      <div v-if="currPos != 1 && currPos != 2">
        <div style="font-weight: bold; font-size: 14px">我的作品</div>
        <!-- 我的作品 -->
        <div class="work_show">
          <!-- 创建项目按钮 -->
          <div
            class="upload_img"
            @click.stop="showCrateProject()"
            v-if="currPos == 0"
          >
            <div class="add_text">{{ addTypeText }}</div>
            <span
              class="iconfont add_icon_img cloud-zhaoshangxiaochengxu-jiahao"
            ></span>
          </div>

          <div
            :class="[
              'item_course',
              (index + 2) % 4 != 0 ? 'hasMargin' : 'noMargin',
            ]"
            v-show="currPos == 0"
            v-for="(item, index) in projectArr"
            :key="index + 1"
            @click.stop="playItemProject(item)"
          >
            <div>
              <el-image class="item_img" :src="item.img" fit="cover">
                <div slot="error" class="image-slot">
                  <el-image :src='require("../../assets/img/default-work-img.png")' fit="cover"></el-image>
                </div>
              </el-image>
            </div>
            <div class="item_bottom">
              <div class="item_bottom_left" :title="item.name">
                {{ item.name }}
              </div>

              <div class="item_bottom_right">
                <span
                  class="iconfont item_icon cloud-bianji1"
                  @click.stop="showCrateProject(item)"
                ></span>
                <span
                  class="iconfont item_icon cloud-shanchu"
                  @click.stop="doDelAction(item, 3)"
                ></span>
              </div>
            </div>
          </div>

          <!-- 添加图片按钮 -->
          <el-upload
            @click.stop.native
            :auto-upload="true"
            action="#"
            :show-file-list="false"
            class="upload_img"
            :before-upload="beforeAvatarUploadImg"
            v-if="currPos == 3"
          >
            <div class="add_text">添加图片</div>
            <span
              class="iconfont add_icon_img cloud-zhaoshangxiaochengxu-jiahao"
            ></span>
          </el-upload>

          <div
            :class="[
              'item_course',
              (index + 2) % 4 != 0 ? 'hasMargin' : 'noMargin',
            ]"
            v-show="currPos == 3"
            v-for="(item, index) in imgArr"
            :key="item.projectId"
          >
            <div>
              <img :preview="index" class="item_img" :src="item.img" />
            </div>
            <div class="item_bottom">
              <div class="item_bottom_left" :title="item.name">
                {{ item.name }}　
              </div>

              <div class="item_bottom_right">
                <span
                  class="iconfont item_icon cloud-bianji1"
                  @click.stop="doEditAction(item, 1)"
                ></span>
                <span
                  class="iconfont item_icon cloud-shanchu"
                  @click.stop="doDelAction(item, 1)"
                ></span>
              </div>
            </div>
          </div>

          <!-- 添加视频按钮 -->
          <el-upload
            @click.stop.native
            :auto-upload="true"
            action="#"
            :show-file-list="false"
            class="upload_img"
            :before-upload="beforeAvatarUploadVideo"
            v-if="currPos == 4"
          >
            <div class="add_text">添加视频</div>
            <span
              class="iconfont add_icon_img cloud-zhaoshangxiaochengxu-jiahao"
            ></span>
          </el-upload>

          <div
            v-show="currPos == 4"
            v-for="(item, index) in videoArr"
            :key="index"
            :class="[
              'item_course',
              (index + 2) % 4 != 0 ? 'hasMargin' : 'noMargin',
            ]"
            @click.stop="showVideoDialog(item)"
          >
            <div>
              <img class="item_img" :src="item.img" />
            </div>
            <div class="item_bottom">
              <div class="item_bottom_left" :title="item.name">
                {{ item.name }}
              </div>

              <div class="item_bottom_right">
                <span
                  class="iconfont item_icon cloud-bianji1"
                  @click.stop="doEditAction(item, 2)"
                ></span>
                <span
                  class="iconfont item_icon cloud-shanchu"
                  @click.stop="doDelAction(item, 2)"
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 30px; font-size: 14px; font-weight: bold">
          优秀作品
        </div>

        <!-- 优秀作品 -->
        <div style="margin-top: 10px">
          <div class="excellent_show" v-if="excellentList.length > 0">
            <div
              :class="[
                'item_course',
                (index + 1) % 4 != 0 ? 'hasMargin' : 'noMargin',
              ]"
              v-for="(item, index) in excellentList"
              :key="index"
              @click.stop="doItemClick(item)"
              @mouseover="item.isShow = true"
              @mouseout="item.isShow = false"
            >
              <div>
                <!--  图片类型 -->
                <img
                  v-show="currPos == 3"
                  :preview="index"
                  class="item_img"
                  :src="item.img"
                />
                <!-- 非图片类型 -->
                <img v-show="currPos != 3" class="item_img" :src="item.img" />
              </div>

              <div class="item_name">
                <span class="name" :title="item.name">{{ item.name }}</span>
              </div>

              <!--  蒙版层 -->
              <div class="mengban" v-show="item.isShow"></div>
            </div>
          </div>
          <el-empty description="暂无优秀作品数据~" v-else></el-empty>
        </div>
      </div>

      <!-- Python项目 -->
      <div class="download_page" v-if="currPos == 1">
        <a href="http://cloud.ycsjedu.com/Download/python-3.8.3.exe" download>
          <span class="iconfont down_icon cloud-xiazai"></span>
          <div class="text">Python下载</div>
        </a>
      </div>

      <!-- C++项目 -->
      <div class="download_page" v-if="currPos == 2">
        <a href="http://cloud.ycsjedu.com/Download/c++.exe" download>
          <span class="iconfont down_icon cloud-xiazai"></span>
          <div class="text">C++下载</div>
        </a>
      </div>
    </div>

    <!-- 加载框部分 -->
    <loading v-if="showLoading"></loading>

    <!-- 创作作品的弹框 -->
    <ShowCreateProject
      v-if="showCreateProjectDialog"
      :showDialog="showCreateProjectDialog"
      @close="showCreateProjectDialog = false"
      title="创建作品"
      :projectInfo="projectInfo"
      :iframeSrc="iframeSrc"
    ></ShowCreateProject>

    <!-- 创建项目的弹框 -->
    <CreateCourse
      @close="closeCreateCourseDialog"
      :showDialog="showCreateCourseDialog"
      title="创建项目"
      @doCreateCourse="doCreateCourse"
    />

    <!-- 播放视频的弹框 -->
    <PlayVideoDialog
      v-if="showPlayVideoDialog"
      :showDialog="showPlayVideoDialog"
      @close="showPlayVideoDialog = false"
      :title="viedoName"
      :playObj="playObj"
    >
    </PlayVideoDialog>

    <!-- 播放项目的弹框 -->
    <PlayProjectDialog
      v-if="showPlayProjectDialog"
      @close="showPlayProjectDialog = false"
      :showDialog="showPlayProjectDialog"
      :projectInfo="projectInfo"
    />

    <!-- 删除的弹框 -->
    <DelItemDialog
      ref="del_item"
      v-if="showDelDialog"
      :showDialog="showDelDialog"
      @close="showDelDialog = false"
      :title="delTitle"
      @delItem="delItem"
    ></DelItemDialog>

    <!-- 修改的弹框 -->
    <EditNameDialog
      ref="edit_name"
      v-if="showEditDialog"
      :showDialog="showEditDialog"
      @close="showEditDialog = false"
      :title="editTitle"
      @editItemName="editItemName"
    ></EditNameDialog>
  </div>
</template>
<script>
//创建作品的弹框
import ShowCreateProject from "@/views/dialog/ShowCreateProject";

//创建项目作品弹框
import CreateCourse from "@/views/dialog/CreateCourse";

//播放视频的弹框
import PlayVideoDialog from "@/views/dialog/PlayVideoDialog";

//播放项目的弹框
import PlayProjectDialog from "@/views/dialog/PlayProjectDialog";

//编辑名称的弹框
import EditNameDialog from "@/views/dialog/EditNameDialog";

//删除条目的弹框
import DelItemDialog from "@/views/dialog/DelItemDialog";
export default {
  data() {
    return {
      //导航栏选中的位置
      currPos: 0,
      navigationData: [
        {
          name: "Scratch",
        },
        {
          name: "Python",
        },
        {
          name: "C++",
        },
        {
          name: "图片",
        },
        {
          name: "视频",
        },
      ],
      addTypeText: "创建项目",
      showCreateCourseDialog: false,
      showEditCourseDialog: false,
      showDelCourseDialog: false,

      //页面加载的Loading
      showLoading: true,

      //上传的Loading框
      loading: null,

      //项目的pid
      pid: "",

      //项目名称
      projectName: "",

      //编辑弹框的标题
      editTitle: "编辑",

      //是否显示编辑弹框的控制值
      showEditDialog: false,

      //删除弹框的条比
      delTitle: "删除",

      //是否显示编辑项目弹框的控制值
      showDelDialog: false,

      //视频播放的名称
      viedoName: "",

      //是否显示播放视频弹框的控制值
      showPlayVideoDialog: false,

      //是否显示创建项目的弹框
      showCreateCourseDialog: false,

      //是否显示播放项目的弹框
      showPlayProjectDialog: false,

      //图片数据
      imgArr: [],

      //视频数据
      videoArr: [],

      //项目数据
      projectArr: [],

      //优秀作品
      excellentList: [],

      //需要被编辑或者删除的条目
      actionObj: null,

      //操作的类型 1图片 2视频 3项目
      actionType: -1,

      //播放视频传递的对象
      playObj: {
        //视频地址
        work_file: "",
        //视频封面
        work_cover: "",
      },

      //创建作品的弹框
      showCreateProjectDialog: false,
      projectInfo: "",
      iframeSrc: "",
      iframeHost: this.$api.SCRATCH3_URL,
    };
  },
  created() {},
  mounted() {
    //请求页面数据
    this.httpData();

    window.addEventListener(
      "message",
      (e) => {
        var params = e.data;
        this.showCreateProjectDialog = false;
        this.httpData();
      },
      false
    );
  },
  methods: {
    //获取页面列表数据
    async httpData() {
      let param = new Object();
      param.type = 8;
      param.userId = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      switch (this.currPos) {
        case 0:
          //Scratch
          param.workType = 3;
          break;
        case 1:
          //Python
          param.workType = 4;
          break;
        case 2:
          //C++
          param.workType = 5;
          break;
        case 3:
          //图片
          param.workType = 1;
          break;
        case 4:
          //视频
          param.workType = 2;
          break;
      }
      let res = await this.$http.fetchPost(this.$api.WRITE_CENTER, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;

        //获取优秀作品的数据
        this.excellentList = res.data.data.excellentList;

        //分别获取图片,视频,项目的列表数据
        if (this.currPos == 0) {
          //项目
          this.projectArr = res.data.data.noExcellentList;
        } else if (this.currPos == 3) {
          //图片
          this.imgArr = res.data.data.noExcellentList;
          this.$previewRefresh();
        } else if (this.currPos == 4) {
          //视频
          this.videoArr = res.data.data.noExcellentList;
        }
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },
    //选择图片文件的操作
    beforeAvatarUploadImg(file) {
      //jpeg的限定
      const isJPG = file.type === "image/jpeg";

      //png格式的限定
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.showErrorMsg("上传图片只能是 JPG或者PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.showErrorMsg("上传图片大小不能超过 2MB!");
        return;
      }

      //JPG和PNG并且大小限定2M
      const res = (isJPG || isPNG) && isLt2M;
      if (res) {
        //上传给服务器的图片文件
        //开启loading
        this.showUploadLoading();

        //上传文件
        this.uploadFile(1, file);
      }
      return res;
    },
    //获取公共请求参数 work_type 图片1,视频2, file上传的文件
    getComParams(work_type, file) {
      let param = new FormData();
      param.append("type", 1);
      param.append("uid", this.getUserInfo().uid);
      param.append("curriculum_center_id", "0");
      param.append("curriculum_chapter_id", "0");
      param.append("chapter_class_id", "0");
      param.append("work_type", work_type);
      param.append("section_id", 0);
      param.append("token", this.getUserInfo().token);
      param.append("file", file);
      return param;
    },

    /**
     * 定义上传文件的方法
     * @param type 上传的文件类型 1图片 2视频
     * @param file 上传的文件
     * @returns
     */
    uploadFile(type, file) {
      this.$http
        .fetchFormDataPost(this.$api.POST_ACTION, this.getComParams(type, file))
        .then((res) => {
          //关闭弹框
          this.closeLoading();
          if (res.data.state == 200) {
            //重新请求页面数据
            this.httpData();
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.closeLoading();
          this.showErrorMsg("服务器请求异常,上传失败");
        });
    },

    //处理视频文件的方法---.mp4文件
    beforeAvatarUploadVideo(file) {
      //mp4的限定
      const isMP4 = file.type === "video/mp4";

      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isMP4) {
        this.showErrorMsg("上传视频只能是 MP4 格式 !");
        return;
      }
      if (!isLt10M) {
        this.showErrorMsg("上传视频大小不能超过 10MB!");
        return;
      }

      //MP4并且大小限定5M
      const res = isMP4 && isLt10M;

      if (res) {
        //开启loading
        this.showUploadLoading();
        //上传文件
        this.uploadFile(2, file);
      }
      return res;
    },

    //显示Loading的方法,上传图片和视频时都需要显示出来
    showUploadLoading() {
      //进行文件的上传并显示Loading效果
      this.loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
    },

    //关闭Loading的方法
    closeLoading() {
      //上传成功后关闭loading
      if (!this.isEmpty(this.loading)) {
        this.loading.close();
      }
    },
    //点击编辑的操作 type 1:编辑图片 2:编辑视频 3:编辑项目
    doEditAction(item, type) {
      //1:记录下当前操作的条目
      this.actionObj = item;

      //2:记录下当前操作的类型
      this.actionType = type;

      //3:显示编辑的弹框
      this.showEditDialog = true;
    },

    //点击删除的操作 type 1:编辑图片 2:编辑视频 3:编辑项目
    doDelAction(item, type) {
      //1:记录下当前操作的条目
      this.actionObj = item;

      //2:记录下当前操作的类型
      this.actionType = type;

      //3:显示编辑的弹框
      this.showDelDialog = true;
    },

    //编辑后的确定事件
    async editItemName(name) {
      //1:做编辑的上传服务器处理
      //2:请求成功后同步当前条目的状态
      //3:关闭编辑弹框
      let param = new Object();
      param.type = 2;
      param.id = this.actionObj.projectId;
      param.work_content = name;
      param.uid = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      let res = await this.$http.fetchPost(this.$api.POST_ACTION, param);
      if (res.data.state == 200) {
        //更改成功后同步当前操作条目的名称即可
        this.actionObj.name = name;
        this.$refs.edit_name.showCommitLoading = false;
        //关闭弹框
        this.showEditDialog = false;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.$refs.edit_name.showCommitLoading = false;
      }
    },

    //删除
    async delItem() {
      //1:做删除的上传服务器处理
      //2:请求成功后同步当前条目的状态
      //3:关闭删除弹框
      let param = new Object();
      param.type = 3;
      param.id = this.actionObj.projectId;
      param.work_type = this.actionType;
      param.uid = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      param.work_file = this.getWorkFile(this.actionObj.img);
      let res = await this.$http.fetchPost(this.$api.POST_ACTION, param);
      if (res.data.state == 200) {
        //将当前记录的条目从对应的数组中移除
        if (this.actionType == 1) {
          this.remove(this.actionObj, this.imgArr);
        } else if (this.actionType == 2) {
          this.remove(this.actionObj, this.videoArr);
        } else if (this.actionType == 3) {
          this.remove(this.actionObj, this.projectArr);
        }
        //关闭删除Loading
        this.$refs.del_item.showCommitLoading = false;
        //关闭弹框
        this.showDelDialog = false;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.$refs.del_item.showCommitLoading = false;
      }
    },

    //获取删除的文件名
    getWorkFile(work_file) {
      if (this.isEmpty(work_file)) {
        return;
      }
      return work_file.substring(work_file.lastIndexOf("/") + 1);
    },
    //显示创建项目的弹框
    showCrateProject(item) {
      // console.log(item);
      //打开创建作品
      this.projectInfo = item;
      this.showCreateProjectDialog = true;
      setTimeout(() => {
        console.log("aaa");
        var ts = new Date().getTime();
        this.iframeSrc = `${this.iframeHost}${
          item
            ? `#${item.projectId}&ts=${ts}&isStartCloud=true&uid=${
                this.getUserInfo().uid
              }&name=${item.name}`
            : `?ts=${ts}&isStartCloud=true&uid=${this.getUserInfo().uid}&name=`
        }`;
      }, 800);

      setTimeout(() => {
        let scratch3Id = document.getElementById("scratch3Id");
        console.log(scratch3Id);
        scratch3Id.contentWindow.postMessage({ isStartCloud: true }, "*");
      }, 2500);
    },
    //创建项目点击确定后的操作
    doCreateCourse(name) {
      //这里是不是先需要请求后台接口判断项目名称是否重复
      if (this.addTypeText == "创建项目") {
        //这里携带账号信息以及作品名称信息单独打开Scratch界面
        window.open(this.$api.SCRATCH3_URL, "_blank");
      }
    },

    //显示播放视频的弹框
    showVideoDialog(item) {
      //播放视频
      this.viedoName = item.name;

      //视频地址
      this.playObj.work_file = item.workCover;

      //视频封面
      this.playObj.work_cover = item.img;

      this.showPlayVideoDialog = true;
    },

    //打开项目的操作
    playItemProject(item) {
      this.projectInfo = item;
      this.showPlayProjectDialog = true;
    },

    isShow(item, index) {
      item.isShow = true;
      for (let i = 0; i < this.myWorkData.length; i++) {
        if (index != i) {
          this.myWorkData[i].isShow = false;
        }
      }
    },
    //导航栏的点击事件
    onSelect(item, pos) {
      if (this.currPos != pos) {
        this.currPos = pos;
        switch (this.currPos) {
          case 0:
            this.addTypeText = "创建项目";
            break;
          case 1:
            this.addTypeText = "创建项目";
            break;
          case 2:
            this.addTypeText = "创建项目";
            break;
          case 3:
            this.addTypeText = "添加图片";
            break;
          case 4:
            break;
        }
        //重新请求页面数据
        this.httpData();
      }
    },

    // 打开编辑作品弹框
    doEdit(index) {
      if (this.addTypeText == "创建项目") {
        this.showEditCourseDialog = true;
      }
    },

    //删除作品弹框
    doDelete(index) {
      if (this.addTypeText == "创建项目") {
        this.showDelCourseDialog = true;
      }
    },
    //关闭创建作品的弹框
    closeCreateCourseDialog() {
      this.showCreateCourseDialog = false;
    },

    //关闭编辑作品的弹框
    closeEditCourseDialog() {
      this.showEditCourseDialog = false;
    },

    //关闭删除作品的弹框
    closeDelCourseDialog() {
      this.showDelCourseDialog = false;
    },

    //条目的点击事件
    doItemClick(item) {
      //判断一下点击的类型,如果是图片类型不做处理,视频打开视频播放的弹框,Scratch打开播放项目的界面
      if (this.currPos == 0) {
        //播放项目
        this.pid = item.projectId;
        this.projectName = item.name;
        this.showPlayProjectDialog = true;
      } else if (this.currPos == 4) {
        //打开视频的弹框
        this.showVideoDialog(item);
      } else if (this.currPos == 1) {
        //Python
      } else if (this.currPos == 2) {
        //C++
      }
    },
  },
  computed: {},

  components: {
    CreateCourse,
    PlayVideoDialog,
    PlayProjectDialog,
    EditNameDialog,
    DelItemDialog,
    ShowCreateProject,
  },
};
</script>
<style lang='scss' scoped>
.write_center {
  height: calc(100vh - 87px);
  overflow-y: auto;
  background: #eff2f7;
  position: relative;

  .create_project {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .if_show_content {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: $common_width;
    height: calc(100vh - 87px);
    margin: 0 auto;
  }

  .Navigation {
    width: 30%;
    height: 10%;
    .no_select {
      background-color: deepskyblue;
      border-radius: 18px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      display: block;
      text-align: center;
      color: white;
      cursor: pointer;
    }
    .select {
      background-color: rgb(245, 200, 51);
      border-radius: 18px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      display: block;
      text-align: center;
      color: black;
      cursor: pointer;
    }
  }

  .work_show {
    width: 100%;
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .add_course {
      position: relative;
      margin-bottom: 20px;
      background: #66bbfe;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      border-radius: 14px;
      color: white;
      font-weight: 1;
      cursor: pointer;
      box-shadow: darkgrey px 1px 1px 1px;
      margin-right: 30px;
      .add_text {
        font-size: 28px;
        margin-top: 15%;
      }
      .add_icon {
        font-size: 60px;
        margin-top: 10%;
      }
    }

    .upload_img {
      position: relative;
      background: #66bbfe;
      display: flex;
      justify-content: center;
      height: 200px;
      flex-direction: column;
      align-items: center;
      width: 23%;
      border-radius: 14px;
      font-weight: 1;
      cursor: pointer;
      margin-right: 30px;
      .add_text {
        font-size: 28px;
        color: white;
        padding-bottom: 20px;
      }
      .add_icon_img {
        color: white;
        font-size: 60px;
      }
    }

    .item_course {
      position: relative;
      margin-bottom: 20px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      border-radius: 14px;
      color: black;
      font-weight: bold;
      cursor: pointer;
      box-shadow: darkgrey 0px 0px 7px 1px;
    }

    .item_img {
      margin-top: 12px;
      width: 240px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;

      .el-image {
        width: 240px;
        height: 140px;
      }
    }

    .item_bottom {
      margin: 0 14px;
      font-weight: normal;
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .item_bottom_left {
        margin-left: 18px;
        flex: 1;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
        font-size: 12px;
      }
      .item_bottom_right {
        margin-right: 18px;
        display: inline-block;
      }

      .item_icon {
        font-size: 20px;
        margin-left: 4px;
      }
      .item_name {
        margin-top: 10px;
      }
    }

    .mengban {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      position: absolute;
      background: rgba($color: #000000, $alpha: 0.2);
    }
    .hasMargin {
      margin-right: 30px;
    }
    .noMargin {
      margin-right: 0px;
    }
  }

  .excellent_show {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .item_course {
      position: relative;
      margin-bottom: 20px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      border-radius: 14px;
      color: black;
      font-weight: bold;
      cursor: pointer;
      box-shadow: darkgrey 0 0 7px 1px;
    }
    .item_img {
      margin-top: 10px;
      width: 240px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
    }
    .item_name {
      width: 100%;
      margin-top: 10px;
      font-size: 12px;
      font-weight: normal;
      color: $text_gray;
      .name {
        width: 50%;
        margin-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .mengban {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      position: absolute;
      background: rgba($color: #000000, $alpha: 0.2);
    }
    .hasMargin {
      margin-right: 30px;
    }
    .noMargin {
      margin-right: 0px;
    }
  }
}

//去除滚动条
.write_center::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.dialogstyle {
  border-radius: 8px;
  width: 778px;
  height: 364px;
  border: #60ceb8 solid 2px;
  background: #000000;
}
.download_page {
  width: 24%;
  text-align: center;
  align-items: center;
  margin-top: 3%;

  .down_icon {
    font-size: 40px;
    cursor: pointer;
    margin-left: 4px;
    color: $text_gray;
  }
  .text {
    cursor: pointer;
    color: $text_gray;
    font-size: 16px;
    line-height: 30px;
  }
}
</style>
